<template>
	<CSidebar 
		fixed 
		:minimize="minimize"
		:show="show"
		@update:show="(value) => $store.commit('set', ['sidebarShow', value])"
		>
		<CSidebarBrand class="d-md-down-none">APEX</CSidebarBrand>

		<CSidebarNav>
			<CSidebarNavItem name="Dashboard" to="/dashboard/sort_num/asc" icon="cil-speedometer"></CSidebarNavItem>
			<CSidebarNavDropdown name="Users" icon="cil-user-plus"
				v-show="$store.state.perm['system.User'].get
					|| $store.state.perm['system.User'].post">
				<CSidebarNavItem v-show="$store.state.perm['system.User'].get"
					name="Users" to="/users"></CSidebarNavItem>
				<CSidebarNavItem v-show="$store.state.perm['system.User'].post"
					name="Add user" to="/user/add"></CSidebarNavItem>
			</CSidebarNavDropdown>
			<CSidebarNavDropdown name="User Groups" icon="cil-group"
				v-show="$store.state.perm['system.UserGroup'].get
					|| $store.state.perm['system.UserGroup'].post">
				<CSidebarNavItem v-show="$store.state.perm['system.UserGroup'].get"
					name="User Groups" to="/groups"></CSidebarNavItem>
				<CSidebarNavItem v-show="$store.state.perm['system.UserGroup'].get"
					name="Add User Group" to="/group/add"></CSidebarNavItem>
			</CSidebarNavDropdown>
			<CSidebarNavDropdown name="Tokens" icon="cil-fingerprint"
				v-show="$store.state.perm['system.GuestUserTokens'].get
					|| $store.state.perm['system.GuestUserTokens'].post">
				<CSidebarNavItem v-show="$store.state.perm['system.GuestUserTokens'].get"
					name="Tokens" to="/tokens"></CSidebarNavItem>
				<CSidebarNavItem v-show="$store.state.perm['system.GuestUserTokens'].post"
					name="Add Token" to="/token/add"></CSidebarNavItem>
			</CSidebarNavDropdown>     
			<CSidebarNavDropdown name="Data Sources" icon="cil-folder-open"
				v-show="$store.state.perm['arbitrage.DataSources'].get
					|| $store.state.perm['arbitrage.DataSources'].post">
				<CSidebarNavItem v-show="$store.state.perm['arbitrage.DataSources'].get"
					name="Data Sources" to="/data/sources"></CSidebarNavItem>
				<CSidebarNavItem v-show="$store.state.perm['arbitrage.DataSources'].post"
					name="Add Data Source" to="/data/source/add"></CSidebarNavItem>
				<CSidebarNavItem v-show="$store.state.perm['arbitrage.DataSourceLogs'].get"
					name="Data Sources Logs" to="/data/source/logs"></CSidebarNavItem>
			</CSidebarNavDropdown>
			<CSidebarNavDropdown name="Input Data" icon="cil-data-transfer-down"
				v-show="$store.state.perm['arbitrage.InputDatas'].get
					|| $store.state.perm['arbitrage.InputDatas'].post">
				<CSidebarNavItem v-show="$store.state.perm['arbitrage.InputDatas'].get"
					name="Input Data" to="/data/input"></CSidebarNavItem>
				<CSidebarNavItem v-show="$store.state.perm['arbitrage.InputDatas'].post"
					name="Add Input Data" to="/data/input/add"></CSidebarNavItem>
			</CSidebarNavDropdown>
			<CSidebarNavDropdown name="Specific Data" icon="cil-data-transfer-down"
				v-show="$store.state.perm['arbitrage.SystemCustomValues'].get
					|| $store.state.perm['arbitrage.SystemCustomValues'].post">
				<CSidebarNavItem v-show="$store.state.perm['arbitrage.SystemCustomValues'].get"
					name="Specific Data" to="/data/custom"></CSidebarNavItem>
				<CSidebarNavItem v-show="$store.state.perm['arbitrage.SystemCustomValues'].post"
					name="Add Specific Data" to="/data/custom/add"></CSidebarNavItem>
			</CSidebarNavDropdown>
			<CSidebarNavDropdown name="Data Processes" icon="cil-calculator"
				v-show="$store.state.perm['arbitrage.InputProccess'].get
					&& $store.state.perm['arbitrage.InputProccess'].post">
				<CSidebarNavItem v-show="$store.state.perm['arbitrage.InputProccess'].get"
					name="Data Processes" to="/data/proccesses"></CSidebarNavItem>
				<CSidebarNavItem v-show="$store.state.perm['arbitrage.InputProccess'].post"
					name="Add Data Processes" to="/data/proccesses/add"></CSidebarNavItem>
				<CSidebarNavItem v-show="$store.state.perm['arbitrage.OutputDatas'].get"
					name="Output Data" to="/data/output"></CSidebarNavItem>
			</CSidebarNavDropdown>
			<CSidebarNavDropdown name="Triggers" icon="cil-bell"
				v-show="$store.state.perm['arbitrage.InputProccessTriggers'].get
					|| $store.state.perm['arbitrage.InputProccessTriggers'].post">
				<CSidebarNavItem v-show="$store.state.perm['arbitrage.InputProccessTriggers'].get"
					name="Triggers" to="/triggers"></CSidebarNavItem>
				<CSidebarNavItem v-show="$store.state.perm['arbitrage.InputProccessTriggers'].post"
					name="Add Trigger" to="/trigger/add"></CSidebarNavItem>
			</CSidebarNavDropdown> 
			<CSidebarNavDropdown name="Telegram Accounts" icon="cil-send"
				v-show="$store.state.perm['system.TelegramAccounts'].get
					|| $store.state.perm['system.TelegramAccounts'].post">
				<CSidebarNavItem v-show="$store.state.perm['system.TelegramAccounts'].get"
					name="Telegram Accounts" to="/telegram/accounts"></CSidebarNavItem>
				<CSidebarNavItem v-show="$store.state.perm['system.TelegramAccounts'].post"
					name="Add Telegram Account" to="/telegram/account/add"></CSidebarNavItem>
			</CSidebarNavDropdown> 
	</CSidebarNav>

	<CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
    </CSidebar>
</template>

<script>
export default {
    computed: {
        show() {
            return this.$store.state.sidebarShow
        },
        minimize() {
            return this.$store.state.sidebarMinimize
        }
    }
}
</script>
