var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CSidebar',{attrs:{"fixed":"","minimize":_vm.minimize,"show":_vm.show},on:{"update:show":function (value) { return _vm.$store.commit('set', ['sidebarShow', value]); }}},[_c('CSidebarBrand',{staticClass:"d-md-down-none"},[_vm._v("APEX")]),_c('CSidebarNav',[_c('CSidebarNavItem',{attrs:{"name":"Dashboard","to":"/dashboard/sort_num/asc","icon":"cil-speedometer"}}),_c('CSidebarNavDropdown',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['system.User'].get
					|| _vm.$store.state.perm['system.User'].post),expression:"$store.state.perm['system.User'].get\n\t\t\t\t\t|| $store.state.perm['system.User'].post"}],attrs:{"name":"Users","icon":"cil-user-plus"}},[_c('CSidebarNavItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['system.User'].get),expression:"$store.state.perm['system.User'].get"}],attrs:{"name":"Users","to":"/users"}}),_c('CSidebarNavItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['system.User'].post),expression:"$store.state.perm['system.User'].post"}],attrs:{"name":"Add user","to":"/user/add"}})],1),_c('CSidebarNavDropdown',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['system.UserGroup'].get
					|| _vm.$store.state.perm['system.UserGroup'].post),expression:"$store.state.perm['system.UserGroup'].get\n\t\t\t\t\t|| $store.state.perm['system.UserGroup'].post"}],attrs:{"name":"User Groups","icon":"cil-group"}},[_c('CSidebarNavItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['system.UserGroup'].get),expression:"$store.state.perm['system.UserGroup'].get"}],attrs:{"name":"User Groups","to":"/groups"}}),_c('CSidebarNavItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['system.UserGroup'].get),expression:"$store.state.perm['system.UserGroup'].get"}],attrs:{"name":"Add User Group","to":"/group/add"}})],1),_c('CSidebarNavDropdown',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['system.GuestUserTokens'].get
					|| _vm.$store.state.perm['system.GuestUserTokens'].post),expression:"$store.state.perm['system.GuestUserTokens'].get\n\t\t\t\t\t|| $store.state.perm['system.GuestUserTokens'].post"}],attrs:{"name":"Tokens","icon":"cil-fingerprint"}},[_c('CSidebarNavItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['system.GuestUserTokens'].get),expression:"$store.state.perm['system.GuestUserTokens'].get"}],attrs:{"name":"Tokens","to":"/tokens"}}),_c('CSidebarNavItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['system.GuestUserTokens'].post),expression:"$store.state.perm['system.GuestUserTokens'].post"}],attrs:{"name":"Add Token","to":"/token/add"}})],1),_c('CSidebarNavDropdown',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['arbitrage.DataSources'].get
					|| _vm.$store.state.perm['arbitrage.DataSources'].post),expression:"$store.state.perm['arbitrage.DataSources'].get\n\t\t\t\t\t|| $store.state.perm['arbitrage.DataSources'].post"}],attrs:{"name":"Data Sources","icon":"cil-folder-open"}},[_c('CSidebarNavItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['arbitrage.DataSources'].get),expression:"$store.state.perm['arbitrage.DataSources'].get"}],attrs:{"name":"Data Sources","to":"/data/sources"}}),_c('CSidebarNavItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['arbitrage.DataSources'].post),expression:"$store.state.perm['arbitrage.DataSources'].post"}],attrs:{"name":"Add Data Source","to":"/data/source/add"}}),_c('CSidebarNavItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['arbitrage.DataSourceLogs'].get),expression:"$store.state.perm['arbitrage.DataSourceLogs'].get"}],attrs:{"name":"Data Sources Logs","to":"/data/source/logs"}})],1),_c('CSidebarNavDropdown',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['arbitrage.InputDatas'].get
					|| _vm.$store.state.perm['arbitrage.InputDatas'].post),expression:"$store.state.perm['arbitrage.InputDatas'].get\n\t\t\t\t\t|| $store.state.perm['arbitrage.InputDatas'].post"}],attrs:{"name":"Input Data","icon":"cil-data-transfer-down"}},[_c('CSidebarNavItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['arbitrage.InputDatas'].get),expression:"$store.state.perm['arbitrage.InputDatas'].get"}],attrs:{"name":"Input Data","to":"/data/input"}}),_c('CSidebarNavItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['arbitrage.InputDatas'].post),expression:"$store.state.perm['arbitrage.InputDatas'].post"}],attrs:{"name":"Add Input Data","to":"/data/input/add"}})],1),_c('CSidebarNavDropdown',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['arbitrage.SystemCustomValues'].get
					|| _vm.$store.state.perm['arbitrage.SystemCustomValues'].post),expression:"$store.state.perm['arbitrage.SystemCustomValues'].get\n\t\t\t\t\t|| $store.state.perm['arbitrage.SystemCustomValues'].post"}],attrs:{"name":"Specific Data","icon":"cil-data-transfer-down"}},[_c('CSidebarNavItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['arbitrage.SystemCustomValues'].get),expression:"$store.state.perm['arbitrage.SystemCustomValues'].get"}],attrs:{"name":"Specific Data","to":"/data/custom"}}),_c('CSidebarNavItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['arbitrage.SystemCustomValues'].post),expression:"$store.state.perm['arbitrage.SystemCustomValues'].post"}],attrs:{"name":"Add Specific Data","to":"/data/custom/add"}})],1),_c('CSidebarNavDropdown',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['arbitrage.InputProccess'].get
					&& _vm.$store.state.perm['arbitrage.InputProccess'].post),expression:"$store.state.perm['arbitrage.InputProccess'].get\n\t\t\t\t\t&& $store.state.perm['arbitrage.InputProccess'].post"}],attrs:{"name":"Data Processes","icon":"cil-calculator"}},[_c('CSidebarNavItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['arbitrage.InputProccess'].get),expression:"$store.state.perm['arbitrage.InputProccess'].get"}],attrs:{"name":"Data Processes","to":"/data/proccesses"}}),_c('CSidebarNavItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['arbitrage.InputProccess'].post),expression:"$store.state.perm['arbitrage.InputProccess'].post"}],attrs:{"name":"Add Data Processes","to":"/data/proccesses/add"}}),_c('CSidebarNavItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['arbitrage.OutputDatas'].get),expression:"$store.state.perm['arbitrage.OutputDatas'].get"}],attrs:{"name":"Output Data","to":"/data/output"}})],1),_c('CSidebarNavDropdown',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['arbitrage.InputProccessTriggers'].get
					|| _vm.$store.state.perm['arbitrage.InputProccessTriggers'].post),expression:"$store.state.perm['arbitrage.InputProccessTriggers'].get\n\t\t\t\t\t|| $store.state.perm['arbitrage.InputProccessTriggers'].post"}],attrs:{"name":"Triggers","icon":"cil-bell"}},[_c('CSidebarNavItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['arbitrage.InputProccessTriggers'].get),expression:"$store.state.perm['arbitrage.InputProccessTriggers'].get"}],attrs:{"name":"Triggers","to":"/triggers"}}),_c('CSidebarNavItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['arbitrage.InputProccessTriggers'].post),expression:"$store.state.perm['arbitrage.InputProccessTriggers'].post"}],attrs:{"name":"Add Trigger","to":"/trigger/add"}})],1),_c('CSidebarNavDropdown',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['system.TelegramAccounts'].get
					|| _vm.$store.state.perm['system.TelegramAccounts'].post),expression:"$store.state.perm['system.TelegramAccounts'].get\n\t\t\t\t\t|| $store.state.perm['system.TelegramAccounts'].post"}],attrs:{"name":"Telegram Accounts","icon":"cil-send"}},[_c('CSidebarNavItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['system.TelegramAccounts'].get),expression:"$store.state.perm['system.TelegramAccounts'].get"}],attrs:{"name":"Telegram Accounts","to":"/telegram/accounts"}}),_c('CSidebarNavItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.perm['system.TelegramAccounts'].post),expression:"$store.state.perm['system.TelegramAccounts'].post"}],attrs:{"name":"Add Telegram Account","to":"/telegram/account/add"}})],1)],1),_c('CSidebarMinimizer',{staticClass:"d-md-down-none",nativeOn:{"click":function($event){return _vm.$store.commit('set', ['sidebarMinimize', !_vm.minimize])}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }